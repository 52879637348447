<template>
  <log-quick-submit
    v-model="note"
    :placeholder="$t('ACTIVITY.CONTACTS.WRITE_NOTES_ABOUT_THIS_CALL')"
    :return-placeholder="$t('ACTIVITY.CONTACTS.TO_LOG_A_CALL')"
    :loading="loading"
    :text-required="false"
    @submit="onSubmit"
  >
    <template #supplemental>
      <slot name="supplemental" />
    </template>
    <template #actionBar>
      <notes-dropdown
        v-model="callDisposition"
        :options="callStatuses"
        :colors-for-options="COLORS_BY_DISPOSITION_NOTE_STATUS"
        :placeholder="$t('ACTIVITY.SELECT_CALL_DISPOSITION')"
        class="min-w-50"
      />
    </template>
  </log-quick-submit>
</template>

<script lang="ts">
import { computed, ref } from "vue";
import { usePromiseWrapper } from "@/hooks/common";
import { useCommunicationStore } from "@/stores/communication";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import type { CallLog, CallNotePayload } from "@/models/communicationLogs";
import { COLORS_BY_DISPOSITION_NOTE_STATUS } from "@/helpers/constants/colors";
import type { CallDisposition } from "@/enums/communicationLogs";
import LogQuickSubmit from "@/components/comunicationLogs/forms/LogQuickSubmit.vue";

interface Props {
  callLog: CallLog;
}
</script>
<script setup lang="ts">
import NotesDropdown from "@/components/ui/NotesDropdown.vue";

const props = defineProps<Props>();
const emit = defineEmits<{
  "callNote:create": [];
}>();

const { getCommunicationList, updateCallNote } = useCommunicationStore();
const { getters } = useStore();
const route = useRoute();

const note = ref(props.callLog.data.body ?? "");
const callDisposition = ref(props.callLog.data.call_disposition);

const callDispositions = computed<string[]>(
  () => getters["options/callDispositions"]
);

const callStatuses = computed(() =>
  Object.entries(callDispositions.value).map(([id, value]) => ({
    id,
    name: value
  }))
);

const { loading, fetchWrapper: createNote } = usePromiseWrapper(
  async (payload: CallNotePayload) => {
    await updateCallNote(props.callLog.data.id, payload);
    emit("callNote:create");
    const filters = route.query;

    await getCommunicationList(filters);
  }
);

const onSubmit = async () => {
  const payload = {
    note: note.value,
    call_disposition: callDisposition.value as CallDisposition
  };
  await createNote(payload);
};
</script>
